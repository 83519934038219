import { styled } from "styled-components";
import NavbarView from "./components/NavbarView";

import ContentView from "./containers/ContentView";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import PortfolioPageView from "./pages/PortfolioPageView";
import ProjectPageView from "./pages/ProjectPageView";
import ExperiencePageView from "./pages/ExperiencePageView";

export default function App() {
  return (
    <BrowserRouter>

      <Routes>
        
        <Route path="/" element={<ContentView/>} />

        <Route path="/projects/*" element={<ProjectPageView/>} />

        <Route path="/projects" element={<PortfolioPageView/>} />

        <Route path="/experience/developer" element={<ExperiencePageView contentID={0} pageID={"developer"}/>} />

        <Route path="/experience/designer" element={<ExperiencePageView contentID={1} pageID={"designer"}/>} />

        <Route path="/experience/manager" element={<ExperiencePageView contentID={2} pageID={"manager"}/>} />

        <Route path="/education" element={<ExperiencePageView contentID={3} pageID={"education"}/>} />

        {/* Handles invalid urls back to /about */}
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>

      <NavWrapper>
        <NavbarView/>
      </NavWrapper>
    </BrowserRouter>
  );
}

const NavWrapper = styled.div`
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100px;
    overflow-y: hidden;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);  
`