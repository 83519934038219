import { createSlice } from "@reduxjs/toolkit";

export const tileSlice = createSlice({
    name: 'selectedTile',
    initialState: {
        value: {
            selectedID: 0
        }
    },
    reducers: {
        selectTile: (state, action) => {
            state.value.selectedID = action.payload
        },
        resetTileSelection: (state) => {
            state.value.selectedID = 0
        }
    }
})

export const { selectTile, resetTileSelection } = tileSlice.actions

export default tileSlice.reducer