import { styled } from "styled-components";
import { Tiles } from "../models/tiles.model";
import { useEffect, useRef, useState } from "react";
import { B1, B2, B3, S3, T1, T1F, T3, T4 } from "../designsystem/Typography";
import { useSelector } from "react-redux";
import store from "../reducers/store";
import { selectTile } from "../reducers/tileReducer";
import { LightColors } from "../designsystem/Colors";
import { useWindowDimensions } from "../utility/Hooks";
import { ImageTag } from "../designsystem/GlobalStyles";
import { useNavigate } from "react-router-dom";
import { homepageInteractor } from "../interactors/HomepageInteractor";
import { getGridPositioning } from "../designsystem/GlobalStyles";
import { getHomepageSectionsDB } from "../interactors/DatabaseInteractor";
import Lottie from "react-lottie-player";
import loadingAnimationData from "../assets/loadingAnimation2.json"
// import loadingAnimationData from "../assets/97443-loading-gray.json"

export default function DesktopHomeView() {

    const [sections, setSections] = useState(null)

    const navigate = useNavigate()

    const selectedID = useSelector(store => store.tile.value.selectedID)
    const windowDimensions = useWindowDimensions();

    const [tappedID, setTappedID] = useState(null)

    const [containerSize, setContainerSize] = useState(840)
    const [rowHeight, setRowHeight] = useState(400)
    const [gridOffset, setGridOffset] = useState(50)

    const [topRowTiles, setTopRowTiles] = useState([])
    const [bottomRowTiles, setBottomRowTiles] = useState([])

    const lottieRef = useRef();

    const topRowInteractions = homepageInteractor.slice(0, 3)
    const bottomRowInteractions = homepageInteractor.slice(3, 6)

    useEffect(() => {
        const {offset, height, rowHeight} = getGridPositioning(windowDimensions.height)
        setContainerSize(height)
        setGridOffset(offset)
        setRowHeight(rowHeight)
    }, [windowDimensions.height])

    useEffect(() => {
        getHomepageSectionsDB(setSections)
    }, [])

    useEffect(() => {
      if (sections != null) {
        setTopRowTiles(sections.slice(0, 3))
        setBottomRowTiles(sections.slice(3, 6))
      }
    }, [sections])
        
    

    

    return (
        <div>
        {
        sections != null
        ?
        <GridContainer offset={gridOffset} height={containerSize}>
        <TopGridRow 
            selectedID={selectedID}
            height={rowHeight}
        >
            {topRowTiles.map((tile, index) => {
                return (
                    <GridItem key={index} selected={selectedID === tile.id ? true : false} onClick={() => {
                        store.dispatch(selectTile(tile.id))
                    }}>
                        <GridTitleContainer selected={selectedID === tile.id ? true : false}>
                            <T1F>{tile.title1}</T1F>
                            <T1F>{tile.title2}</T1F>
                        </GridTitleContainer>

                        <GridItemText selected={selectedID === tile.id ? true : false}>
                            {tile.description}
                        </GridItemText>

                        <GridItemButton 
                            showButton={selectedID === tile.id || tile.alwaysPresent ? true : false}
                            id={tile.id}
                            tappedID={tappedID}
                            onClick={() => {
                                if (selectedID !== tile.id) {
                                    return
                                }
                                setTappedID(tile.id)
                                setTimeout(() => {
                                    setTappedID(null)
                                    topRowInteractions[index](navigate)
                                }, 200);
                            }}
                        >
                            {tile.buttonText}
                        </GridItemButton>

                        {tile.hasImage
                        ?
                        <GridItemImage 
                            src={tile.imageURL} 
                            id={tile.id} 
                            selected={selectedID === tile.id ? true : false}
                        />
                        :
                        null}
                    </GridItem>
                )
            })}
        </TopGridRow>

        <BottomGridRow 
            selectedID={selectedID}
            height={rowHeight}
        >
            {bottomRowTiles.map((tile, index) => {
                return (
                    <GridItem 
                        key={index} 
                        selected={selectedID === tile.id ? true : false} 
                        onClick={() => {
                            store.dispatch(selectTile(tile.id))
                        }}>
                        <GridTitleContainer selected={selectedID === tile.id ? true : false}>
                            <T1F>{tile.title1}</T1F>
                            <T1F>{tile.title2}</T1F>
                        </GridTitleContainer>

                        <GridItemText selected={selectedID === tile.id ? true : false}>
                            {tile.description}
                        </GridItemText>

                        <GridItemButton 
                            showButton={selectedID === tile.id || tile.alwaysPresent ? true : false}
                            id={tile.id}
                            tappedID={tappedID}
                            onClick={() => {
                                if (selectedID !== tile.id) {
                                    return
                                }
                                setTappedID(tile.id)
                                setTimeout(() => {
                                    setTappedID(null)
                                    bottomRowInteractions[index](navigate)
                                }, 200);
                            }}
                        >
                            {tile.buttonText}
                        </GridItemButton>

                        {tile.hasImage
                        ?
                        <GridItemImage 
                            src={tile.imageURL} 
                            id={tile.id} 
                            selected={selectedID === tile.id ? true : false}
                        />
                        :
                        null}
                    </GridItem>
                )
            })}
        </BottomGridRow>

    </GridContainer>
    : <LoadingAnimation />}
        </div>
    );
}

const GridContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: ${props => props.offset ? props.offset + "px" : "0px"};
    height: ${props => props.height ? props.height + "px" : "820px"};
    width: 100%;
`


const TopGridRow = styled.div`
    position: relative;
    display: grid;
    gap: 10px;
    width: 100%;
    height: ${props => props.height ? props.height + "px" : "400px"};
    grid-template-columns: ${props=>
        props.selectedID === 1 ? "1fr 2fr 1fr" 
        : 
        props.selectedID === 2 ? "1fr 1fr 2fr" 
        : 
        "2fr 1fr 1fr"
    };
    

    transition: grid-template-columns 0.5s ease-in-out;
`

const BottomGridRow = styled.div`
    position: relative;
    display: grid;
    gap: 10px;
    width: 100%;
    height: ${props => props.height ? props.height + "px" : "400px"};
    grid-template-columns: ${props=>
        props.selectedID === 3 ? "2fr 1fr 1fr" 
        : 
        props.selectedID === 4 ? "1fr 2fr 1fr" 
        : "1fr 1fr 2fr"};

    transition: grid-template-columns 0.5s ease-in-out;
`

const GridItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: ${props => props.selected ? "linear-gradient(180deg, rgba(100,214,255,1) 0%, rgba(159,237,255,1) 100%)" : LightColors.regularTile};
    border-radius: 10px;
    overflow: hidden;

    z-index: 0;

    opacity: 100%;

    &:hover {
        cursor: pointer;
        filter: ${props => props.selected ? "brightness(100%)" : "brightness(95%)"};
    }

    transition: all 0.3s ease-in-out;
`

const GridTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    margin-top: 16px;
    margin-left: 16px;

    > * {
        color: ${props => props.selected ? 'white' : 'black'};
    }
`

const GridItemImage = styled(ImageTag)`
    position: absolute;
    max-width: 50%;
    height: 70%;
    object-fit: contain;

    bottom: -30px;
    right: 0px;

    opacity: ${props => props.id === 5 ? "100%" : "50%"};

    margin-right: 16px;

    transition: bottom 0.3s ease-in-out;
`

const GridItemText = styled(B2)`
    opacity: ${props => props.selected ? "100%" : "0%"};
    color: white;
    margin-left: 16px;
    margin-top: 16px;
    width: 50%;
    transition: opacity 0.3s ease-in-out;
`

const GridItemButton = styled(B3)`
    z-index: 10;

    position: absolute;
    bottom: 16px;
    left: 16px;
    color: black;
    background-color: rgba(255,255,255,0.9);
    backdrop-filter: blur(10px);
    padding: 16px 32px;
    border-radius: 50px;

    opacity: ${props => props.showButton ? "100%" : "0%"};

    transform: scale(${props => props.tappedID === props.id ? "0.9" : "1"});

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
    }

    transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out;
`

const AnimationWrapper = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 50%;
`

export function LoadingAnimation() {
    return (
        <AnimationWrapper>
            <Lottie
                loop
                animationData={loadingAnimationData}
                play
                style={{ width: 200, height: 200}}
            />
        </AnimationWrapper>
    )
}
