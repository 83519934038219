import { styled } from "styled-components"
import { useWindowDimensions } from "../utility/Hooks"
import { useEffect, useState } from "react"
import { ImageTag, PageWrapper, WidthAdjustmentDiv, Wrapper, getGridPositioning, HeaderWrapper, HeaderContentWrapper, ClearSelectionButtonWrapper, SkillTagGridWrapper, SkillGrid, SectionsWrapper, RelevantProjectsButton, SectionWrapper, SectionTextSide, SectionImageSide} from "../designsystem/GlobalStyles"
import { B1, B2, B3, B3T, S3, S4, T1, T1F, T2, T2F, T3, T4 } from "../designsystem/Typography"
import SkillTagView from "../components/SkillTagView"
import { LightColors } from "../designsystem/Colors"
import { Sections } from "../models/sections.model"
import { Experience } from "../models/experience.model"
import { Skills } from "../models/skills.model"
import { useNavigate } from "react-router-dom"
import { goToProjectPage } from "../interactors/HomepageInteractor"
import { getPortfolioDB } from "../interactors/DatabaseInteractor"
import { LoadingAnimation } from "../components/DesktopHomeView"
import { filterByProjectID } from "../interactors/SkillInteractor"

export default function PortfolioPageView() {

    const [content, setContent] = useState(null)

    const navigate = useNavigate()

    const windowDimensions = useWindowDimensions()
    const [containerSize, setContainerSize] = useState(840)
    const [gridOffset, setGridOffset] = useState(50)

    const [isHoveringID, setIsHoveringID] = useState(null)

    const [selectedIDs, setSelectedIDs] = useState([])

    useEffect(() => {
        const {offset, height} = getGridPositioning(windowDimensions.height)
        setContainerSize(height)
        setGridOffset(offset)
    }, [windowDimensions.height])

    useEffect(() => {
      getPortfolioDB(setContent)
    }, [])

    useEffect(() => {
        if (content != null && selectedIDs.length > 0) {
            const filteredSections = filterByProjectID(selectedIDs, content.projects, selectedIDs.slice(-1))
            setContent({...content, sections: filteredSections})
        }
    }, [selectedIDs])
    

    return (
       <Wrapper>
            {content != null
            ?
            <PageWrapper offset={gridOffset} height={containerSize}>
            {/* header, tags, etc */}
            <HeaderWrapper>
                <HeaderContentWrapper>
                    <T1F style={{color: "white"}}>{content.title1}</T1F>
                    <T1F style={{color: "white"}}>{content.title2}</T1F>
                </HeaderContentWrapper>

                <div style={{height: "50px"}} />

                <HeaderContentWrapper>
                    <SkillTagGridWrapper>
                        <SkillGrid>
                            {content.skill.map((skill) => {
                                return (
                                    <SkillTagView
                                        body={skill.body}
                                        selected={selectedIDs.includes(skill.id)}
                                        onTapFunction={() => {
                                            console.log("click tag id: ", skill.id)
                                            if (!selectedIDs.includes(skill.id)) {
                                                setSelectedIDs([...selectedIDs, skill.id])
                                            } else {
                                                setSelectedIDs(selectedIDs.filter((id) => id !== skill.id))
                                            }
                                        }}
                                    />
                                )
                            })}
                        </SkillGrid>
                    </SkillTagGridWrapper>


                    {selectedIDs.length > 0
                    ?
                    <ClearSelectionButtonWrapper onClick={() => {
                        setSelectedIDs([])
                    }}>
                        <S4>Clear</S4>
                    </ClearSelectionButtonWrapper>
                    :
                    null}
                </HeaderContentWrapper>
            </HeaderWrapper>

            {/* sections content */}
            <SectionsWrapper>

                {selectedIDs.length > 0
                ?
                <T2F style={{color: LightColors.gray, width: "100%"}}>Selected skills were best applied:</T2F>
                :
                null}

                {/* grid with project thumbnails */}
                <ProjectsGrid isMobile={windowDimensions.width < 800}>
                    {content.projects.map((project) => {
                        return (
                            <ProjectThumbnail 
                                onMouseOver={() => {setIsHoveringID(project.id)}} 
                                onMouseOut={()=>{setIsHoveringID(null)}}
                                onClick={() => {
                                    console.log("click project id: ", project.id)
                                    goToProjectPage(navigate, project.id)
                                }}
                            >

                                <ThumbnailImage src={project.imgURL} isHovering={isHoveringID === project.id}/>

                                <ThumbnailTextWrapper isHovering={isHoveringID === project.id}>
                                    <T4 style={{color: isHoveringID === project.id ? "gray" : "black"}}>
                                        {isHoveringID === project.id ? 'Tap To Learn More' : project.title}
                                    </T4>
                                    <S4>{project.subtitle}</S4>
                                </ThumbnailTextWrapper>

                            </ProjectThumbnail>
                        )
                    })}

                </ProjectsGrid>
            </SectionsWrapper>

            {/* Footer Button */}
        </PageWrapper>
        :
        <LoadingAnimation/>}
       </Wrapper>
    )
}

const ProjectsGrid = styled.div`
    display: grid;
    grid-template-columns: ${props => props.isMobile ? "1fr" : "1fr 1fr"};
    grid-gap: 10px;
    width: 100%;
    margin-bottom: 100px;
`

const ProjectThumbnail = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    background-color: ${LightColors.regularTile};
    display: flex;
    /* justify-content: ${props => props.isHovering ? 'flex-end' : 'flex-start'}; */
    align-items: center;
    border-radius: 14px;
    cursor: pointer;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;

    transition: all 1s ease-in-out;
`

const ThumbnailTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: ${props => props.isHovering ? '80%' : '10%'};
    transition: all 0.5s ease-in-out;
`

const ThumbnailImage = styled(ImageTag)`
    position: absolute;
    object-fit: contain;
    width: 80%;
    height: 80%;
    top: ${props => props.isHovering ? '-30%' : '30%'};
    transition: all 0.5s ease-in-out;
`
