import { styled } from "styled-components";
import { Tiles } from "../models/tiles.model";
import { B1, B2, B3, S3, T1, T1F, T3, T4 } from "../designsystem/Typography";
import { useSelector } from "react-redux";
import store from "../reducers/store";
import { selectTile } from "../reducers/tileReducer";
import { createRef, useEffect, useState } from "react";
import { ImageTag } from "../designsystem/GlobalStyles";
import { LightColors } from "../designsystem/Colors";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { homepageInteractor } from "../interactors/HomepageInteractor";
import { getHomepageSectionsDB } from "../interactors/DatabaseInteractor";
import { LoadingAnimation } from "./DesktopHomeView";

const mobileIcons = [
    require("../assets/welcomeMobileIcon.png"),
    require("../assets/developerMobileIcon.png"),
    require("../assets/designerMobileIcon.png"),
    require("../assets/managerMobileIcon.png"),
    require("../assets/educationMobileIcon.png"),
    require("../assets/projectsMobileIcon.png"),
]

export default function MobileHomeView() {

    const [sections, setSections] = useState(null)

    const navigate = useNavigate()

    const interactions = homepageInteractor

    const selectedID = useSelector(store => store.tile.value.selectedID)
    const [tappedID, setTappedID] = useState(null)
    
    const tileRefs = Tiles.map(() => createRef(null))
    const scrollRef = useRef(null)

    useEffect(() => {
        getHomepageSectionsDB(setSections)
    }, [])

    return (
        <ContentWrapper>

        {sections
        ?
        <div>
            <MenuWrapper>
                <ScrollProgressMenuWrapper>
                    {mobileIcons.map((icon, index) => {
                        return (
                            <MobileIconImg 
                                src={icon}
                                selected={selectedID === index ? true : false}   
                                onClick={() => {
                                    if (selectedID !== index) {
                                        store.dispatch(selectTile(index))
                                        tileRefs[index].current.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'center',
                                        })
                                    }
                                }}
                            />
                        )
                    })}
                </ScrollProgressMenuWrapper>
            </MenuWrapper>

                <TilesWrapper ref={scrollRef}>
                    {sections.map((tile, index) => {
                        return (
                            <GridItem 
                                key={index} 
                                selected={selectedID === tile.id ? true : false} 
                                ref={tileRefs[index]}
                                onClick={() => {
                                    store.dispatch(selectTile(tile.id))
                                    if (selectedID !== tile.id) {
                                        tileRefs[index].current.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'center',
                                        })
                                    }
                                }}
                            >
                                <GridTitleContainer selected={selectedID === tile.id ? true : false}>
                                    <T1F>{tile.title1}</T1F>
                                    <T1F>{tile.title2}</T1F>
                                </GridTitleContainer>

                                {tile.hasImage ? 
                                <GridItemImage 
                                    src={tile.imageURL} 
                                    id={tile.id}
                                    selected={selectedID === tile.id ? true : false}
                                /> 
                                : null}

                                <GridItemText selected={selectedID === tile.id ? true : false}>
                                    {tile.description}
                                </GridItemText>

                                <GridItemButton
                                    showButton={selectedID === tile.id || tile.alwaysPresent ? true : false}
                                    id={tile.id}
                                    tappedID={tappedID}
                                    onClick={() => {
                                        if (selectedID !== tile.id) {
                                            return
                                        }
                                        setTappedID(tile.id)
                                        setTimeout(() => {
                                            setTappedID(null)
                                            interactions[index](navigate)
                                        }
                                        , 200);
                                    }}
                                >
                                    {tile.buttonText}
                                </GridItemButton>
                            </GridItem>
                    )})}
                </TilesWrapper></div>
                :
                <LoadingAnimation/>}    

        </ContentWrapper>
    );
}

const ContentWrapper = styled.div`
    position: absolute;
    width: 100%;
    top: 0px;
`

const TilesWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 85%;
    top: 100px;

    :target {
        scroll-margin-top: 5rem;
    }
`

const GridItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${props => props.selected ? "300px" : "200px"};
    background: ${props => props.selected ? LightColors.selectedTile : LightColors.regularTile};
    border-radius: 10px;
    overflow: hidden;

    z-index: 0;

    scroll-margin-top: 100px;

    opacity: 100%;

    &:hover {
        cursor: pointer;
        filter: ${props => props.selected ? "brightness(100%)" : "brightness(95%)"};
    }

    transition: all 0.3s ease-in-out;
`

const GridTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    margin-top: 16px;
    margin-left: 16px;

    > * {
        color: ${props => props.selected ? 'white' : 'black'};
    }
`

const GridItemImage = styled(ImageTag)`
    position: absolute;
    height: ${props => props.selected & props.id === 5 ? "200px" : "150px"};
    object-fit: contain;

    bottom: -30px;
    right: 0px;
    opacity: ${props => props.id === 5 ? "100%" : "50%"};


    margin-right: 16px;

    transition: height 0.3s ease-in-out;
`

const GridItemText = styled(B2)`
    opacity: ${props => props.selected ? "100%" : "0%"};
    color: white;
    margin-left: 16px;
    margin-top: 16px;
    width: 50%;
    transition: opacity 0.3s ease-in-out;
`

const GridItemButton = styled(B3)`
    z-index: 10;

    position: absolute;
    bottom: 16px;
    left: 16px;
    color: black;
    background-color: rgba(255,255,255,0.9);
    backdrop-filter: blur(10px);
    padding: 16px 32px;
    border-radius: 50px;

    opacity: ${props => props.showButton ? "100%" : "0%"};
    disabled: ${props => props.showButton ? "false" : "true"};

    transform: scale(${props => props.tappedID === props.id ? "0.9" : "1"});

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
    }

    transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out;
    transition-delay: 1s;
    transition-property: disabled;
`



const ScrollProgressMenuWrapper = styled.div`
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 116px;
    right: 0px;
    width: 100%;
    height: 100vw;
    gap: 25px;
`

const MenuWrapper = styled.div`
    position: fixed;
    height: 250vh;
    width: 100%;
    right: 0px;
    top: 0px; 
    transition: filter 0.3s ease-in-out;
`

const MobileIconImg = styled(ImageTag)`
    object-fit: contain;
    height: 40px;
    width: 40px;
    filter: ${props => props.selected ? "saturate(100%)" : "saturate(0%)"};
`