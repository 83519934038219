import styled from "styled-components";

const InterFont = styled.h1`
    font-family: "Inter", sans-serif;
    color: black;
`

const FuturaFont = styled.h1`
    font-family: "Jost", sans-serif;
    color: black;
`

export const T1F = styled(FuturaFont)`
    font-weight: 300;
    font-size: 33px;
`

export const T2F = styled(FuturaFont)`
    font-weight: 300;
    font-size: 28px;
    color: gray;
`

export const T1 = styled(InterFont)`
font-weight: 700;
font-size: 44px;
`

export const T2 = styled(InterFont)`
font-weight: 700;
font-size: 35px;
`

export const T3 = styled(InterFont)`
font-weight: 700;
font-size: 25px;
`

export const T4 = styled(InterFont)`
font-weight: 700;
font-size: 20px;
`

export const S1 = styled(InterFont)`
font-weight: 500;
font-size: 35px;
`

export const S2 = styled(InterFont)`
font-weight: 500;
font-size: 26px;
`

export const S3 = styled(InterFont)`
font-weight: 500;
font-size: 22px;
`

export const S4 = styled(InterFont)`
font-weight: 500;
font-size: 18px;
`

export const B1 = styled(InterFont)`
font-weight: 400;
font-size: 25px;
`

export const B1T = styled(InterFont)`
font-weight: 300;
font-size: 25px;
`

export const B2 = styled(InterFont)`
font-weight: 400;
font-size: 20px;
`

export const B2T = styled(InterFont)`
font-weight: 300;
font-size: 20px;
`

export const B3 = styled(InterFont)`
font-weight: 400;
font-size: 16px;
`

export const B3T = styled(InterFont)`
font-weight: 300;
font-size: 16px;
`

export const B4 = styled(InterFont)`
font-weight: 400;
font-size: 12px;
`

export const B4T = styled(InterFont)`
font-weight: 300;
font-size: 12px;
`
