
//returns sorted sections by selected skills with preference for last selected item
export function filterSectionBySkill(selectedSkillIDs, sections, lastSelectedSkillID) {
   const compare = (a, b) => {
    if ( a.matches < b.matches ){
        return 1;
      }
      if ( a.mathes > b.matches ){
        return -1;
      }
      return 0;
   }
   
   var allMatches = sections.map((section) => {
         var matches = 0
         section.skillsIDs.forEach((skill) => {
              if (selectedSkillIDs.includes(skill)) {
                matches += 1
              }
         })
         return {
            matches: matches,
            id: section.id
         }
    })
    console.log(selectedSkillIDs)
    console.log(sections)
    console.log(allMatches)
    var sortedMatches = allMatches.sort((a, b) => b.matches - a.matches)

    console.log(sortedMatches)

    return sortedMatches.map((match) => {
        return sections[match.id]
    })
}

//returns sorted sections by selected skills with preference for last selected item
export function filterByProjectID(selectedSkillIDs, sections, lastSelectedSkillID) {
    const compare = (a, b) => {
     if ( a.matches < b.matches ){
         return 1;
       }
       if ( a.mathes > b.matches ){
         return -1;
       }
       return 0;
    }
    
    var allMatches = sections.map((section) => {
          var matches = 0
          section.skills.forEach((skill) => {
               if (selectedSkillIDs.includes(skill)) {
                 matches += 1
               }
          })
          return {
             matches: matches,
             id: section.id
          }
     })
     console.log(selectedSkillIDs)
     console.log(sections)
     console.log(allMatches)
     var sortedMatches = allMatches.sort((a, b) => b.matches - a.matches)
 
     console.log(sortedMatches)
 
     return sortedMatches.map((match) => {
         return sections[match.id]
     })
 }

// sections need to follow a schema with IDs, calls and UI should record last selected ID
