import { styled } from "styled-components";
import { B3 } from "../designsystem/Typography";
import { useState } from "react";

export default function SkillTagView(props) {

    const [tapped, setTapped] = useState(false)

    return (
        <Wrapper 
            tapped={tapped} 
            selected={props.selected} 
            isLink={props.isLink}
            onClick={() => {
                console.log("clicked")
                setTapped(true)
                setTimeout(() => {
                    setTapped(false)
                    props.onTapFunction()
                }, 200);
            }}
        >
            <B3>{props.body}</B3>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    min-width: 50px;
    width: fit-content;
    border-radius: 5px;
    background-color: ${props=>props.selected ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.66)"};
    opacity: ${props=>props.tapped ? "0.5" : "1"};
    box-shadow: ${props=>props.selected ? "0px 0px 30px 0px rgba(0,0,0,0.15)" : "none"};

    &:hover {
        cursor: pointer;
        background-color: ${props=>props.selected ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.8)"};
    }

    > * {
        margin: 5px 10px;
        text-decoration: ${props => props.isLink ? "underline" : "none"};
    }

    transition: all 0.3s ease-in-out;
`