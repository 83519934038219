import styled from 'styled-components';
import { WidthAdjustmentDiv } from '../designsystem/GlobalStyles';
import NavbarView from '../components/NavbarView';
import { useIsMobile } from '../utility/Hooks';
import MobileHomeView from '../components/MobileHomeView';
import DesktopHomeView from '../components/DesktopHomeView';

export default function ContentView() {

    const isMobile = useIsMobile()

    return (
        <div>

            <Wrapper>

            {/* central component */}
            {isMobile ?
            <MobileHomeView/>
            :
            <DesktopHomeView/>
            }

            {/* footer */}
            </Wrapper>
        </div>
    );
}

const Wrapper = styled(WidthAdjustmentDiv)`
    position: absolute;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
`
