export const Tiles = [
    {
        id: 0,
        titleLine1: "Welcome to",
        titleLine2: "my cloud.",
        description: "",
        hasImage: false,
        image: null,
        buttonText: "Download CV",
        alwaysPresent: true
    },
    {
        id: 1,
        titleLine1: "Full-Stack",
        titleLine2: "Developer",
        description: "Lorem ipsum",
        hasImage: true,
        image: require('../assets/FullStack.png'),
        buttonText: "My Skills"
    },
    {
        id: 2,
        titleLine1: "Product",
        titleLine2: "Designer",
        description: "Lorem ipsum",
        hasImage: true,
        image: require('../assets/ProductDesign.png'),
        buttonText: "Design Experience"
    },
    {
        id: 3,
        titleLine1: "Business",
        titleLine2: "Manager",
        description: "Lorem ipsum",
        hasImage: true,
        image: require('../assets/BusinessManager.png'),
        buttonText: "Manager Experience"
    },
    {
        id: 4,
        titleLine1: "Education",
        titleLine2: "Record",
        description: "Lorem ipsum",
        hasImage: true,
        image: require('../assets/EducationRecord.png'),
        buttonText: "My Education" 
    },
    {
        id: 5,
        titleLine1: "Projects",
        titleLine2: "Portfolio",
        description: "Lorem ipsum",
        hasImage: true,
        image: require('../assets/ProjectsPortfolio.png'),
        buttonText: "Discover My Projects"
    },

]