import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB_od_qmeFOz14nFquQAkEVwtfaay_wc6Q",
    authDomain: "rakhmanovcloud.firebaseapp.com",
    projectId: "rakhmanovcloud",
    storageBucket: "rakhmanovcloud.appspot.com",
    messagingSenderId: "1062252281523",
    appId: "1:1062252281523:web:eaeaa5fa02ea7c8f347ac6",
    measurementId: "G-RNPV3QMTXK"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export async function getHomepageSectionsDB(setSections) {
    const docRef = doc(db, "sections", "sectionsThumbnail");
    const docSnap = await getDoc(docRef);
    var sections;
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data())
        sections = docSnap.data().sections
        setTimeout(() => {
            setSections(sections)
        }, 1000);
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    return sections;
}

export async function getPageByIdDB(pageID, setPageContent) {
    const docRef = doc(db, "pages", pageID);
    const docSnap = await getDoc(docRef);
    var pageContent;
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data())
        pageContent = docSnap.data()
        setTimeout(() => {
            setPageContent(pageContent)
        }, 1000);
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    return pageContent;
}

export async function getPortfolioDB(setPageContent) {
    const docRef = doc(db, "projects", "projectsThumbnail");
    const docSnap = await getDoc(docRef);
    var pageContent;
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data())
        pageContent = docSnap.data()
        setTimeout(() => {
            setPageContent(pageContent)
        }, 1000);
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    return pageContent;
}

export async function getProjectByIdDB(projectID, setPageContent) {
    const docRef = doc(db, "projects", projectID);
    const docSnap = await getDoc(docRef);
    var pageContent;
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data())
        pageContent = docSnap.data()
        setTimeout(() => {
            setPageContent(pageContent)
        }, 1000);
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    return pageContent;
}