import { styled } from "styled-components"
import { useWindowDimensions } from "../utility/Hooks"
import { useEffect, useState } from "react"
import { ImageTag, PageWrapper, WidthAdjustmentDiv, Wrapper, getGridPositioning, HeaderWrapper, HeaderContentWrapper, ClearSelectionButtonWrapper, SkillTagGridWrapper, SkillGrid, SectionsWrapper, RelevantProjectsButton, SectionWrapper, SectionTextSide, SectionImageSide} from "../designsystem/GlobalStyles"
import { B1, B2, B3, B3T, S3, S4, T1, T1F, T2, T2F, T3 } from "../designsystem/Typography"
import SkillTagView from "../components/SkillTagView"
import { LightColors } from "../designsystem/Colors"
import { Sections } from "../models/sections.model"
import { Experience } from "../models/experience.model"
import { useNavigate } from "react-router-dom"
import { getPageByIdDB } from "../interactors/DatabaseInteractor"
import { LoadingAnimation } from "../components/DesktopHomeView"
import { filterSectionBySkill } from "../interactors/SkillInteractor"


// Reusable page component for occupation experience pages
export default function ExperiencePageView(props) { 

    // const content = Experience[props.contentID]
    const [content, setContent] = useState(null)
    const windowDimensions = useWindowDimensions()
    const [containerSize, setContainerSize] = useState(840)
    const [gridOffset, setGridOffset] = useState(50)

    const [selectedIDs, setSelectedIDs] = useState([])

    useEffect(() => {
        const {offset, height} = getGridPositioning(windowDimensions.height)
        setContainerSize(height)
        setGridOffset(offset)
    }, [windowDimensions.height])

    useEffect(() => {
        getPageByIdDB(props.pageID, setContent)
    }, [])

    useEffect(() => {
        if (content != null && selectedIDs.length > 0) {
            const filteredSections = filterSectionBySkill(selectedIDs, content.sections, selectedIDs.slice(-1))
            console.log(filteredSections)
            setContent({...content, sections: filteredSections})
        }
    }, [selectedIDs])

    return (
       <Wrapper>
            {content != null
            ?
            <PageWrapper offset={gridOffset} height={containerSize}>
            {/* header, tags, etc */}
            <HeaderWrapper>
                <HeaderContentWrapper>
                    <T1F style={{color: "white"}}>{content.title1}</T1F>
                    <T1F style={{color: "white"}}>{content.title2}</T1F>
                </HeaderContentWrapper>

                <div style={{height: "50px"}} />

                <HeaderContentWrapper>
                    <SkillTagGridWrapper>
                        <SkillGrid>
                            {content.skills
                            ?
                            content.skills.map((skill) => {
                                return (
                                    <SkillTagView
                                        body={skill.body}
                                        selected={selectedIDs.includes(skill.id)}
                                        onTapFunction={() => {
                                            console.log("click tag id: ", skill.id)
                                            if (!selectedIDs.includes(skill.id)) {
                                                setSelectedIDs([...selectedIDs, skill.id])
                                            } else {
                                                setSelectedIDs(selectedIDs.filter((id) => id !== skill.id))
                                            }
                                        }}
                                    />
                                )
                            })
                            :
                            null}
                        </SkillGrid>
                    </SkillTagGridWrapper>


                    {selectedIDs.length > 0
                    ?
                    <ClearSelectionButtonWrapper onClick={() => {
                        setSelectedIDs([])
                    }}>
                        <S4>Clear</S4>
                    </ClearSelectionButtonWrapper>
                    :
                    null}
                </HeaderContentWrapper>
            </HeaderWrapper>

            {/* sections content */}
            <SectionsWrapper>

                {selectedIDs.length > 0
                ?
                <T2F style={{color: LightColors.gray, width: "100%"}}>Selected skills were best applied:</T2F>
                :
                null}

                {content.sections.map((section) => {
                    return (
                        <SectionWrapper isMobile={windowDimensions.width < 600}>
                            <SectionTextSide>
                                <T3>{section.title}</T3>

                                <B2>{section.body}</B2>
                            </SectionTextSide>

                            <SectionImageSide src={section.imgURL} />
                        </SectionWrapper>
                    )
                })}
                
            </SectionsWrapper>

            {/* Footer Button */}
        </PageWrapper>
        :
        <LoadingAnimation/>}
       </Wrapper>
    )
}

