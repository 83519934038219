export function goToEducationPage(navigate) {
    navigate("/education")
}

export function goToDeveloperPage(navigate) {
    navigate("/experience/developer")
}

export function goToDesignerPage(navigate) {
    navigate("/experience/designer")
}

export function goToManagerPage(navigate) {
    navigate("/experience/manager")
}

export function goToPortfolioPage(navigate) {
    navigate("/projects")
}

export function downloadCV() {
    window.open("https://firebasestorage.googleapis.com/v0/b/rakhmanovcloud.appspot.com/o/portfolio-projects-images%2FartemRakhmanovCV.pdf?alt=media&token=96f4e625-8b8e-4b60-a356-dd04e9613177")
}

export function goToHomePage(navigate) {
    navigate("/")    
}

export function goToProjectPage(navigate, projectID) {
    navigate("/projects/" + projectID)
}

export const homepageInteractor = [
    downloadCV, goToDeveloperPage, goToDesignerPage, goToManagerPage, goToEducationPage, goToPortfolioPage
]