import styled from 'styled-components';
import { LightColors } from './Colors';

export const WidthAdjustmentDiv = styled.div`
    width: 95%;
    max-width: 1500px;
    left: 50%;
    transform: translateX(-50%);
`

export const ImageTag = styled.img`
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
`

export const Wrapper = styled(WidthAdjustmentDiv)`
    position: absolute;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
`

export const PageWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    top: ${props => props.offset ? props.offset + "px" : "0px"};
    /* height: ${props => props.height ? props.height + "px" : "820px"}; */
    width: 100%;
`
//returns adaptively the offset for the grid and it's size
export function getGridPositioning(wHeight) {
    const navbarHeight = 100
    var offset = 0
    var height = 610
    var rowHeight = 300

    const sideOffset = (wHeight - height) / 2
    offset = Math.max(sideOffset, navbarHeight)

    return {offset, height, rowHeight}
}

export const HeaderWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
    background: rgb(100,214,255);
    background: linear-gradient(180deg, rgba(100,214,255,1) 0%, rgba(159,237,255,1) 100%);

    border-radius: 14px 14px 0px 0px;

    transition: min-height 0.5s ease-in-out;
`

export const HeaderContentWrapper = styled.div`
    margin: 16px;
`

export const ClearSelectionButtonWrapper = styled.div`
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 16px;
    width: 20%;
    text-align: right;
    > * {
        color: gray;
    }
`

export const SkillTagGridWrapper = styled.div`
    position: relative;
    width: 70%;
`

export const SkillGrid = styled.div`
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
`

export const SectionsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    min-height: 250px;
`

export const RelevantProjectsButton = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;

    > * {
        color: ${LightColors.selectedTile};
    }

    > :hover {
            text-decoration: underline;
    }
`

export const SectionWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: ${props => props.isMobile ? "1fr" : "1fr 1fr"};
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    min-height: 250px;
    gap: 10px;
`

export const SectionTextSide = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: top;
    gap: 10px;

    > * {
        text-align: justify;
        text-justify: inter-word;
    }
`

export const SectionImageSide = styled(ImageTag)`
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-top: 30px;

`