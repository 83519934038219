import { styled } from "styled-components"
import { useWindowDimensions } from "../utility/Hooks"
import { useEffect, useState } from "react"
import { ImageTag, PageWrapper, SkillTagGridWrapper, Wrapper, getGridPositioning } from "../designsystem/GlobalStyles"
import { B3, B4T, S3, S4, T1F, T3, T4 } from "../designsystem/Typography"
import { Skills } from "../models/skills.model"
import SkillTagView from "../components/SkillTagView"
import ImageGallery from 'react-image-gallery';
import { useLocation } from "react-router-dom"
import { getProjectByIdDB } from "../interactors/DatabaseInteractor"
import { LoadingAnimation } from "../components/DesktopHomeView"


export default function ProjectPageView() {
    
    const [content, setContent] = useState(null)

    const location = useLocation()

    const image = require('../assets/ProjectsPortfolio.png')
    const logo = require('../assets/icon.png')

    const windowDimensions = useWindowDimensions()
    const [containerSize, setContainerSize] = useState(840)
    const [gridOffset, setGridOffset] = useState(50)

    useEffect(() => {
        const {offset, height} = getGridPositioning(windowDimensions.height)
        setContainerSize(height)
        setGridOffset(offset)
    }, [windowDimensions.height])

    useEffect(() => {
        let tmp = location.pathname.slice(location.pathname.lastIndexOf("/") + 1 , location.pathname.length)
        getProjectByIdDB(tmp, setContent)
    }, [])
    

    const images = [
        {
           original: image,
           thumbnail: image
        },
        {
           original: "https://appwrk.com/wp-content/uploads/2022/09/MicrosoftTeams-image-_2_.webp",
           thumbnail: "https://appwrk.com/wp-content/uploads/2022/09/MicrosoftTeams-image-_2_.webp"
        }
     ];

    function getGalleryImagesObject(imageLinks) {
        let result = []
        imageLinks.forEach((link) => {
            result.push({
                original: link,
                thumbnail: link
            })
        })
        return result
    }

    return (
        <Wrapper>
            {content != null
            ?
            <PageWrapper style={{justifyContent: "flex-start", alignItems: "center"}} offset={100} height={containerSize}>
                
                <ProjectTypeTag style={{backgroundColor: content.type.color}}>
                    <B4T>{content.type.body}</B4T>
                </ProjectTypeTag>

                <T1F>{content.title}</T1F>

                <S4>{content.subtitle}</S4>

                <ProjectLargeImage src={content.imageURL} />

                {/* 3 sections */}
                {content.sections.map((section) => {
                    return (
                        <SectionWrapper>
                            <S3>{section.title}</S3>

                            <B3>{section.body}</B3>

                        </SectionWrapper>
                    )
                })}

                {/* skills & links */}
                <LinkContainer isSkills={true}>
                    <S4>Skills</S4>

                    <ItemWrappingGrid>
                        {content.skills.map((skill) => {
                                            return (
                                                <SkillTagView
                                                    body={skill.body}
                                                    selected={true}
                                                    onTapFunction={() => {
                                                    }}
                                                />
                                            )
                        })}
                    </ItemWrappingGrid>
                </LinkContainer>

                <LinkContainer isSkills={false}>
                    <S4>Links</S4>

                    <ItemWrappingGrid isSkills={false}>
                        {content.links.map((skill) => {
                                            return (
                                                <SkillTagView
                                                    isLink={true}
                                                    body={skill.body}
                                                    selected={true}
                                                    onTapFunction={() => {
                                                        window.open(skill.url)
                                                    }}
                                                />
                                            )
                        })}
                    </ItemWrappingGrid>
                </LinkContainer>


                {/* video* */}

                {/* gallery */}
                <S3>Gallery</S3>
                <ImageGallery items={getGalleryImagesObject(content.gallery)} />

                <div style={{height: "150px"}}/>
                
                {/* logo */}
                <AppLogo src={content.logoURL} />

            </PageWrapper>
            :
            <LoadingAnimation/>
            }
        </Wrapper>
    )
}

const ProjectTypeTag = styled.div`
    border-radius: 25px;
    /* background-color: ${props=>props.color ? props.color : "blue"}; */
    
    > * {
        margin: 5px 10px;
        color: white;
    }
`

const ProjectLargeImage = styled(ImageTag)`
    width: 80%;
    max-width: 600px;
    object-fit: contain;
    margin: 20px 0px;
`

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 20px 0px;
    gap: 20px;

    > * {
        text-align: center;
        text-justify: inter-word;
    }
`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    background: ${props=>props.isSkills ? "linear-gradient(90deg, rgba(100,214,255,1) 0%, rgba(159,237,255,1) 100%)" : "linear-gradient(90deg, rgba(178,163,181,1) 0%, rgba(240,225,243,1) 100%)"};
    border-radius: 14px;
    align-items: center;

    > * {
        margin: 16px 0px;
        color: white;
    }
`

const ItemWrappingGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 80%;

    > * {
        text-decoration: ${props => props.isSkills ? "none" : "underline"};
    }
`

const AppLogo = styled(ImageTag)`
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 20px;
`