import styled from 'styled-components';
import { ImageTag, WidthAdjustmentDiv } from '../designsystem/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { goToHomePage } from '../interactors/HomepageInteractor';

const Icon = require('../assets/icon.png');
const TextLogo = require('../assets/textLogoLight.png');
const BurderIcon = require('../assets/burgerIcon.png');

export default function NavbarView() {

    const navigate = useNavigate()

    return (
        <NavWrapper>

            <SideItemsWrapper>
                <SideLogoImg src={TextLogo} style={{width: "200px"}} onClick={() => {
                    goToHomePage(navigate)
                }}/>

                <SideLogoImg src={Icon} onClick={() => {
                    goToHomePage(navigate)
                }}/>
            </SideItemsWrapper>

            {/* <SideItemsWrapper>
                <SideLogoImg src={Icon} onClick={() => {
                    goToHomePage(navigate)
                }}/>

                <SideLogoImg src={BurderIcon}/>
            </SideItemsWrapper>

            <CentralLogoImg src={TextLogo} onClick={() => {
                    goToHomePage(navigate)
            }}/> */}
        </NavWrapper>
    );
}

const NavWrapper = styled(WidthAdjustmentDiv)`
    position: relative;
    top: 0px;
    height: 100px;
    overflow-y: hidden;
`

const SideItemsWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
`

const SideLogoImg = styled(ImageTag)`
    width: 40px;
    height: 50px;
    object-fit: contain;
`

const CentralLogoImg = styled(ImageTag)`
    width: 200px;
    height: 50%;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`